<template>
  <div class="col-12 col-lg-4 ml-auto checkout-header">
    <div class="border-box no-brdr-xs order-note">
        <!-- <h4 class="block-title block-title-new" v-html="settingData.sandwich_description" v-if="productType === 'sandwich'"></h4> -->
        <h4 class="block-title block-title-new" v-html="settingData.note" v-if="productType === 'sandwich'"></h4>
        <h4 class="block-title block-title-new" v-html="settingData.snacks_checkout_page_message" v-if="productType === 'snack'"></h4>
        <form autocomplete="off" class="form form-checkout"
      @submit.prevent="processCheckout">
            <div class="form-group checkout-form checkout-form-name">
                <input
                  v-validate="'required'"
                  v-model="checkoutData.full_name"
                  type="text"
                  :name="$t('Checkout.FORM_FIELD.FULL_NAME')"
                  class="form-control tenzar-form-control"
                  :placeholder="$t('Checkout.FORM_FIELD.FULL_NAME') + ':'"
                  :class="{ 'is-invalid': errors.has($t('Checkout.FORM_FIELD.FULL_NAME'))}"
                  autocomplete="name"
                />
                <div
                  v-if="errors.has($t('Checkout.FORM_FIELD.FULL_NAME'))"
                  class="invalid-feedback"
                >
                <p>{{ errors.first($t('Checkout.FORM_FIELD.FULL_NAME')) }}</p>
              </div>
            </div>
            <div class="form-group checkout-form checkout-form-mobile">
              <input
                v-validate="'required'"
                v-model="checkoutData.mobile_no"
                type="tel"
                :name="$t('Checkout.FORM_FIELD.MOBILE')"
                class="form-control tenzar-form-control"
                :placeholder="$t('Checkout.FORM_FIELD.MOBILE') + ':'"
                :class="{ 'is-invalid': errors.has($t('Checkout.FORM_FIELD.MOBILE'))}"
                autocomplete="tel"
              />
              <div
                v-if="errors.has($t('Checkout.FORM_FIELD.MOBILE'))"
                class="invalid-feedback"
              >
                <p>{{ errors.first($t('Checkout.FORM_FIELD.MOBILE')) }}</p>
              </div>
            </div>
            <div class="form-group checkout-form checkout-form-email">
              <input
                v-validate="'required|email'"
                v-model="checkoutData.email_id"
                data-vv-validate-on="change|blur"
                type="email"
                :name="$t('Checkout.FORM_FIELD.EMAIL')"
                class="form-control tenzar-form-control"
                :placeholder="$t('Checkout.FORM_FIELD.EMAIL') + ':'"
                :class="{ 'is-invalid': errors.has($t('Checkout.FORM_FIELD.EMAIL'))}"
                autocomplete="email"
              />
              <div
                v-if="errors.has($t('Checkout.FORM_FIELD.EMAIL'))"
                class="invalid-feedback"
              >
                <p>{{ errors.first($t('Checkout.FORM_FIELD.EMAIL')) }}</p>
              </div>
            </div>
            <div class="form-group checkout-form checkout-form-postal">
              <input
                v-model="checkoutData.postal_code"
                v-validate="'required'"
                type="text"
                :name="$t('Checkout.FORM_FIELD.POSTAL_CODE')"
                class="form-control tenzar-form-control"
                :placeholder="$t('Checkout.FORM_FIELD.POSTAL_CODE') + ':'"
                :class="{ 'is-invalid': errors.has($t('Checkout.FORM_FIELD.POSTAL_CODE'))}"
              />
              <div
                v-if="errors.has($t('Checkout.FORM_FIELD.POSTAL_CODE'))"
                class="invalid-feedback"
              >
                <p>{{ errors.first($t('Checkout.FORM_FIELD.POSTAL_CODE')) }}</p>
              </div>
            </div>
            <div class="form-group checkout-form checkout-form-time" v-if="productType === 'sandwich'">
              <select
                v-validate="'required'"
                :class="{'is-invalid': errors.has($t('Checkout.FORM_FIELD.SANDWICH_PICKUP_DATE'))}"
                :name="$t('Checkout.FORM_FIELD.SANDWICH_PICKUP_DATE')"
                class="form-control tenzar-form-control"
                v-model="selectedPickupDate"
              >
                  <option :value="null">{{ $t('Checkout.FORM_FIELD.SANDWICH_PICKUP_DATE') }}</option>
                  <option
                    v-for="(value, name) in pickupDates"
                    :key="name"
                    :value="value.date"
                  >
                    {{ value.date_detail }}
                  </option>
              </select>
              <div v-if="errors.has($t('Checkout.FORM_FIELD.SANDWICH_PICKUP_DATE'))" class="invalid-feedback">
                <p>{{ errors.first($t('Checkout.FORM_FIELD.SANDWICH_PICKUP_DATE')) }}</p>
              </div>
            </div>

            <div class="form-group checkout-form checkout-form-time" v-if="productType === 'sandwich'">
              <select
                v-validate="'required'"
                :class="{'is-invalid': errors.has($t('Checkout.FORM_FIELD.SANDWICH_PICKUP_TIME')) || tempValidation }"
                :name="$t('Checkout.FORM_FIELD.SANDWICH_PICKUP_TIME')"
                class="form-control tenzar-form-control"
                v-model="selectedPickupTime"
                @change="changePickupTime"
              >
                  <option :value="null">{{ $t('Checkout.FORM_FIELD.SANDWICH_PICKUP_TIME') }}</option>
                  <option
                    v-for="(value) in pickupTimes"
                    :key="value.id"
                    :value="value.start_time"
                  >
                    {{ value.start_time.slice(0,5) }}
                  </option>
              </select>
              <div v-if="tempValidation || errors.has($t('Checkout.FORM_FIELD.SANDWICH_PICKUP_TIME'))" class="invalid-feedback">
                <p>
                  <!-- {{ errors.first($t('Checkout.FORM_FIELD.SANDWICH_PICKUP_TIME')) }} -->
                  {{ $t('Checkout.FORM_FIELD.VALIDATION_SANDWICH_PICKUP_TIME') }}
                </p>
              </div>
            </div>

            <div class="form-group checkout-form checkout-form-time" v-else>
              <select
                v-validate="'required'"
                :class="{ 'is-invalid': errors.has($t('Checkout.FORM_FIELD.PICKUP_TIME'))}"
                :name="$t('Checkout.FORM_FIELD.PICKUP_TIME')"
                class="form-control tenzar-form-control"
                v-model="checkoutData.time_slot">
                <option :value="null">{{ settingData.pickup_time_label || $t('Checkout.FORM_FIELD.PICKUP_TIME') }}</option>
                 <option
                  v-for="timeSlot in filterStartTime"
                  :key="timeSlot.start_time"
                  :value="timeSlot.start_time"
                  >
                    <!-- {{ timeSlot.parseTime }} -->
                    {{ timeSlot.start_time.slice(0,5) }}
                  </option>
              </select>
              <div
                  v-if="errors.has($t('Checkout.FORM_FIELD.PICKUP_TIME'))"
                  class="invalid-feedback"
                >
                <p>{{ errors.first($t('Checkout.FORM_FIELD.PICKUP_TIME')) }}</p>
              </div>
            </div>
            <div class="form-group checkout-form checkout-form-feedback">
              <textarea
                  v-model="checkoutData.feedback"
                  type="text"
                  class="form-control tenzar-form-control"
                  :placeholder="$t('Checkout.FORM_FIELD.QUESTION_FEEDNBACK') + ':'"
              style="min-height: 100px;"></textarea>
            </div>
            <div class="snack-payment-type mb-3" v-if="productType === 'snack' && snackPaymentTypes.length > 1">
              <div class="form-check" v-for="paymentType in snackPaymentTypes" :key="paymentType.id">
                <input class="form-check-input" type="radio" :id="paymentType.id" :value="paymentType.id" v-model="checkoutData.payment_type">
                <label class="form-check-label" :for="paymentType.id">
                 {{ paymentType.name }}
                </label>
              </div>
            </div>
            <div class="sandwich-payment-type mb-3" v-if="productType === 'sandwich' && sandwichPaymentTypes.length > 1">
              <div class="form-check" v-for="paymentType in sandwichPaymentTypes" :key="paymentType.id">
                <input class="form-check-input" type="radio" :id="paymentType.id" :value="paymentType.id" v-model="checkoutData.payment_type">
                <label class="form-check-label" :for="paymentType.id">
                 {{ paymentType.name }}
                </label>
              </div>
            </div>
            <div class="custom-control custom-checkbox" v-if="showGeneralTerms === 1">

                <input type="checkbox" class="custom-control-input" v-validate="'required'" id="customCheck1" name="terms" v-model="checkoutData.terms">
                <label class="custom-control-label checkbox-label " :class="{'is-invalid' : errors.has('terms')}" for="customCheck1">{{$t('Checkout.FORM_FIELD.AGREE')}}<a href="#" class="link-text" data-toggle="modal" data-target="#exampleModalCenter">{{$t('Checkout.FORM_FIELD.TERMS_CONDITION')}}</a></label>
                <div
                  v-if="errors.has('terms')"
                  class="invalid-feedback"
                >
                  <p>{{ errors.first('terms') }}</p>
                </div>
            </div>
            <div class="row justify-content-center order-btn">
                <div class="col-9 col-sm-7 button-header order-submit">
                    <button type="submit" class="btn button-element checkout-btn" :disabled="cartItemCounts <= 0">{{$t('Checkout.CONFIRM_ORDER')}}</button>
                </div>
            </div>
        </form>
        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close button-element" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
               <iframe height="500" width="100%" :src="termsFileUrl" allowfullscreen frameborder="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'CheckoutForm',

    $_veeValidate: {
      validator: 'new'
    },

    data () {
      return {
        checkoutData: {
          full_name: '',
          mobile_no: '',
          email_id: '',
          time_slot: null,
          feedback: '',
          postal_code: '',
          cart:[],
          setting_id: "1",
          terms: false,
          type:this.$store.state.cartBucket[0].type,
          payment_type: null,
          sandwich_order_time_table: null
        },
        timeSlots: [],
        openModal: false,
        showGeneralTerms: '',
        showGeneralTermsUrl: '',
        snackPaymentTypes: [],
        sandwichPaymentTypes: [],
        productType: '',
        // selectedPickupDate: this.$store.state.selectedPickupDate,
        // selectedPickupTime: this.$store.state.selectedPickupTime,
        tempValidation: false,
        deviceType: ''
      }
    },

    mounted () {
      if(this.$store.state.cartBucket[0].type){
        this.productType = this.$store.state.cartBucket[0].type;
      }else{
        this.productType = localStorage.getItem('product_type');
      }
    },

    computed: {
      ...mapGetters({ orderItems: 'getCartItems', settingId: 'getSettingId', cartItemCounts: 'getCartItemCount', settingData : 'getSettingData',pickupDates : 'getPickupDates', pickupTimes : 'getPickupTimes' }),

      selectedPickupDate: {
        get(){
          return this.$store.getters.getSelectedPickupDate
        },
        set(date){
          this.$store.state.selectedPickupDate = date
        }
      },
      selectedPickupTime: {
        get(){
          return this.$store.getters.getSelectedPickupTime
        },
        set(time){
          this.$store.state.selectedPickupTime = time
        }
      },

        filterStartTime() {
          // const currentTime = (new Date()).getTime()
          const timeSlots =  this.timeSlots.map(ele => {
              const [hour, minute] = ele.start_time.split(":");

              return { ...ele, ...{
                time: {
                  hour,
                  minute
                },
                parseTime: hour.concat(":" + minute)
              }};
          })
          let last_ele = timeSlots.length - 1
          let final_timeslot = []
          for(let ts in timeSlots){
            if(last_ele == ts){
              final_timeslot.push(timeSlots[ts])
              final_timeslot.push({
                start_time: timeSlots[ts].end_time,
                end_time: timeSlots[ts].end_time
              })
            }else{
              final_timeslot.push(timeSlots[ts])
            }
          }
          return final_timeslot
          // if ( this.productType === 'sandwich') {
          //   return timeSlots
          // } else {

          //   // return timeSlots.filter(item => {
          //   //   const parseTime = new Date()
          //   //   parseTime.setHours(item.time.hour)
          //   //   parseTime.setMinutes(item.time.minute)
          //   //   parseTime.setSeconds(0, 0)

          //   //   return parseTime.getTime() >= currentTime
          //   // })
          // }
        },

        termsFileUrl () {
          if ( !this.showGeneralTermsUrl.endsWith(".pdf") ) { return this.showGeneralTermsUrl }
          return  `https://docs.google.com/viewer?url=${this.showGeneralTermsUrl}&embedded=true`;
        },
    },

    created () {
      this.loadTimeSlots()
      if(this.$route.query.device_type != undefined){
        this.deviceType = this.$route.query.device_type
      }
    },

    methods: {
      ...mapActions(['makeCartEmpty']),

      processCheckout () {
        if(this.productType == 'sandwich' && this.selectedPickupTime == null){
          this.tempValidation = true
        }else{
          this.tempValidation = false
        }
        if(this.productType == 'sandwich'){
          this.checkoutData.time_slot = this.selectedPickupTime
        }else{
          this.selectedPickupTime = this.checkoutData.time_slot
          this.selectedPickupDate = 'fake-temp-data'
        }
        this.$validator.validateAll().then(isValid => {
          if (isValid) {
            this.$root.$load(true)
            const checkoutData = { ...this.checkoutData }
            checkoutData.setting_id = this.settingId
            checkoutData.sandwich_order_time_table = this.selectedPickupDate+' '+this.selectedPickupTime
            checkoutData.device_type = this.deviceType
            checkoutData.cart = this.orderItems.map((item) => {
              return {
                product_id: item.id,
                qty: item.quantity,
                adjustment: item.adjustment
                  .filter(adjustment => adjustment.selected)
                  .map(adjustment => {
                    return {"adjustment_id":  adjustment.id}
                  })
              }
            })

            this.$http.post('place-order', checkoutData)
              .then((response) => {
                if (response.status === 200) {
                  this.$store.commit('SET_SANDWICH_SUCCESSDATA', response.data.data);
                  this.makeCartEmpty()
                  if ( response.data.data.payment_url ) {
                    this.$toast.open({
                      message: this.$i18n.t('Checkout.TOAST_MESSAGE.REDIRECT_TO_PAYMENT'),
                      type: 'info'
                    })
                    // window.location.href = response.data.data.payment_url
                    this.$router.push({ name: 'Redirect', query: {
                                        lang: this.$store.state.appLang,
                                        payment_url: response.data.data.payment_url,
                                        email_id: response.data.data.email_id,
                                        order_date_time: response.data.data.order_date_time,
                                        deep_link: response.data.data.deep_link
                                      }
                                    })
                  } else {
                    this.$router.push({ name: 'OrderSuccess', query: { lang: this.$store.state.appLang } })
                  }
                }
              })
              .catch((e) => { console.log(e) })
              .finally(() => { this.$root.$load(false) })
          }
        })
      },
      loadPickupDates () {
        if (this.type === 'sandwich') {
          this.$root.$load(true)
          this.$http.post(`opening-day`, {
            setting_id: 1// this.settingsId
          })
          .then((response) => {
            if(response.status === 200){
              this.$store.commit('SET_PICKUP_DATES', response.data.data)
            }
          })
          .catch((e) => { console.log(e) })
          .finally(() => { this.$root.$load(false) })
        }
      },
      loadPickupTimes () {
        if (this.type === 'sandwich') {
          this.$root.$load(true)
          this.$http.post(`get-slot`, {
            setting_id: 1// this.settingsId
          })
          .then((response) => {
            if(response.status === 200){
              this.$store.commit('SET_PICKUP_TIMES', response.data.data.sandwich)
            }
          })
          .catch((e) => { console.log(e) })
          .finally(() => { this.$root.$load(false) })
        }
      },
      loadTimeSlots () {
        this.$root.$load(true)
        const settingsId = this.settingId
        this.$http.post('get-slot', { setting_id: settingsId })
          .then((response) => {
            if (response.status === 200) {
              const slotData = response.data.data;
              this.showGeneralTerms  = slotData.show_general_terms;
              this.snackPaymentTypes = slotData.snack_payment_type;
              this.sandwichPaymentTypes = slotData.sandwich_payment_type;
              if ( localStorage.getItem('product_type') === 'snack') {
                this.timeSlots = slotData.snack
                this.showGeneralTermsUrl = slotData.snack_show_general_terms_url;
                this.checkoutData.payment_type = this.snackPaymentTypes[0].id;
              } else {
                this.timeSlots = slotData.sandwich
                this.showGeneralTermsUrl = slotData.show_general_terms_url;
                this.checkoutData.payment_type = this.sandwichPaymentTypes[0].id;
              }
            }
          })
          .catch((e) => { console.log(e) })
          .finally(() => { this.$root.$load(false) })
      },
      changePickupTime(){
        if(this.selectedPickupTime != null){
          this.tempValidation = false
        }
      }
    }
  }
</script>
